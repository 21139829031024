import React from 'react';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';
import SubstackWidget from '../components/newsletter/substack-widget';

const StyledPostContainer = styled.main`
  max-width: 1000px;
`;
const StyledPostHeader = styled.header`
  margin-bottom: 50px;

  .tag {
    margin-right: 10px;
  }
`;
const StyledPostContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: var(--light-slate);
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
    border-radius: var(--border-radius);
    font-size: var(--fz-sm);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }
`;

const PostTemplate = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { title, date, tags, authorName, authorImage } = frontmatter;

  return (
    <Layout location={location}>
      <Helmet title={title} />

      <StyledPostContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/blog">All memories</Link>
        </span>

        {/* Author Section */}
        {authorName && authorImage && (
          <StyledAuthorSection>
            <img src={authorImage} alt={authorName} className="author-image" />
            <span className="author-name">{authorName}</span>
          </StyledAuthorSection>
        )}

        <StyledPostHeader>
          <h1 className="medium-heading">{title}</h1>
          <p className="subtitle">
            <time>
              {new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </time>
            <span>&nbsp;&mdash;&nbsp;</span>
            {tags &&
              tags.length > 0 &&
              tags.map((tag, i) => (
                <Link key={i} to={`/blog/tags/${kebabCase(tag)}/`} className="tag">
                  #{tag}
                </Link>
              ))}
          </p>
        </StyledPostHeader>

        <StyledPostContent dangerouslySetInnerHTML={{ __html: html }} />

        <StyledAboutSection>
          <h2>About Dejan Georgiev</h2>
          <p>
            Hey! I'm Dejan, Founder of <a href="https://uliasti.com">Uliasti</a>, makers of{' '}
            <a href="https://kaufpedia.ch">Kaufpedia</a>, <a href="https://advanzo.app">Advanzo</a>{' '}
            and <a href="https://techify.space">Techify</a>. Subscribe below to follow my thinking
            on business, faith, tech, product development, and whatever else is on my mind.
          </p>

          <p className="rss-feed">Thanks for visiting, thanks for reading.</p>
          {/* <div className="subscribe-form">
            <input type="email" placeholder="Type your email..."/>
            <button type="button">Subscribe</button>
          </div>*/}

          <SubstackWidget />
          {/*<form
            className="subscribe-form"
            action="https://dejangeorgiev.us17.list-manage.com/subscribe/post?u=898ef9e25062aa64e1f8c482a&amp;id=fab4bb5b49&amp;f_id=00235ce0f0" // Replace with your Mailchimp form action URL
            // API KEy 3b8262a231bb4eca300a12ffecdc74dd-us17
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
          >
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              placeholder="Type your email..."
              required
            />
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="email" name="EMAIL" className="required email"
                     id="mce-EMAIL" required="" value=""/>
            </div>
            <button type="submit" name="subscribe" id="mc-embedded-subscribe">
              Subscribe
            </button>
          </form>*/}
        </StyledAboutSection>
      </StyledPostContainer>
    </Layout>
  );
};

export default PostTemplate;

PostTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        date
        slug
        tags
        authorName
        authorImage
      }
    }
  }
`;

const StyledAuthorSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  padding: 0;

  .author-image {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
  }

  .author-name {
    font-size: 1em;
    color: var(--light-slate);
    text-transform: uppercase;
  }
`;

const StyledAboutSection = styled.section`
  background: var(--light-navy); // Adjust the color to match your theme
  color: var(--slate);
  padding: 40px;
  border-radius: var(--border-radius);
  margin: 100px 0;
  text-align: center;

  h2 {
    color: var(--light-slate);
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .subscribe-form {
    margin-top: 20px;
  }

  input[type='email'] {
    border: 1px solid var(--slate);
    background-color: var(--navy);
    padding: 10px;
    margin-right: 10px;
    border-radius: var(--border-radius);
    color: var(--light-slate);
  }

  button {
    ${({ theme }) => theme.mixins.smallButton};
  }

  .rss-feed {
    margin-top: 20px;
  }
`;
